.inputDiv{
    border-bottom: 1px solid black;
    /* height: 40px; */
}

input, textarea{
    border: 0;
    background-color: transparent;
    outline: none;
    height: 100%;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 0;
}


input::placeholder, textarea::placeholder{
    font-size: 16px;
    color: black;
    font-weight: 600;
    transition: 0.3s ease-in-out;
}