#root{
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}

.root{
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

.introSection{
    height: 100vh;
    width: 100vw;
    background-color: var(--primary-color);
    transition: 0.4s ease-in;
}

.introSection .description{
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    z-index: 99;
    width: 40%;
    bottom: 0;
    top: 0;
    margin: 100px;
    gap: 20px;
}

.introSection.header{
    height: 70vh;
}

.textWrapper{
    position: relative;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
}

.description .designation{
    font-size: 50px;
    font-weight: 600;
    text-align: left;
}

.description .introduction{
    text-align: left;
    font-size: 30px;
}

.circleDesign{
    position: absolute;
    border-radius: 50%;
    background-color: var(--secondary-color);
    top: 0;
    opacity: 0;
    transform: scale(0.5);
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.134);
    animation: showCircle 0.8s forwards;
}

.aboutSection{
    width: 100vw;
    min-height: 100vh;
    background-image: url('.../../../../../public/about.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0;
}

.aboutInnerCard{
    height: fit-content;
    width: 75%;
    background-color: white;
    flex-direction: column;
    padding: 50px 100px;
    gap: 10px;
    box-shadow: 2px 2px 20px 1px black;
}

.aboutInnerCard .cardTop{
    flex-direction: column;
    padding: 30px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.419);
    gap: 30px;
}

.cardTop .title{
    color: var(--secondary-color);
    font-weight: 060;
    font-size: 18px;
}

.cardTop .aboutText{
    font-size: 35px;
    text-align: center;
    width: 100%;
}

.aboutInnerCard .cardBottom{
    flex: 1;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    padding: 20px 0;
}

.imageDiv{
    height: 80px;
    width: 80px;
    background-size: contain;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(222, 222, 222);
}

.experienceSection{
    flex-direction: column;
    padding: 50px 100px;
    gap: 30px;
}

.experienceSection .experienceTitleWrapper{
    justify-content: flex-start;
    margin: 50px 0;
}

.sectionTitle{
    font-size: 55px;
    font-weight: 500;
}

.experiences{
    flex-direction: column;
    width: 80%;
}

.companyLink{
    font-weight: 600;
    font-size: 22px;
    transition: 0.4s ease-in-out;
    text-decoration: underline;
}

.companyLink:hover{
    color: var(--secondary-color);
}

.experience{
    flex-direction: column;
    border-top: 1px solid rgb(158, 158, 158);
    padding: 20px 0px;
    gap: 25px;
}

.experienceTopLeft .experienceTitle{
    font-size: 30px;
    width: 80%;
    text-align: left;
}

.experience .experienceTop{

}

.experience .experienceBottom{
    
}

.experienceDesc{
    text-align: left;
}

.experienceTop .experienceTopLeft{
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.experienceTop .date{
    font-weight: 400;
    font-size: 18px;
}

.experience .experienceDesc{
    font-size: 17px;
    color: black;
}

.expertiseSection{
    background-color: var(--secondary-color);
    padding: 10px;
    min-height: 80vh;
}

.expertiseSection{
    flex-direction: column;
    padding: 50px 0  ;
}

.expertiseSection .sectionTitle{
    margin-bottom: 50px;
}

.expertiseCard{
    width: 70%;
}

.expertiseCard .expertiseCardLeft{
    position: relative;
    flex: 1;
    background-color: var(--primary-color);
    padding: 10px;
    border-radius: 4px;
    min-height: 400px;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.197);
}

.expertiseCardLeft span{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    flex: 0.8;
}

.expertiseCard .expertiseCardRight{
    flex: 1;
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.caret{
    position: absolute;
    margin: auto;
    right: -17px;
    top: 0;
    bottom: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 18px solid var(--primary-color);
    width: 0;
    height: 0;
}

.footer{
    flex-direction: column;
    background-color: var(--secondary-color);
}

.footerWrapper{
    width: 85%;
}

.footer .title{
    font-size: 20px;
    font-weight: 600;
}

.quote{
    font-size: 40px;
}

.quoteWrapper{
    border-bottom: 1px solid rgb(73, 73, 73);
    padding: 50px 0;
}

.footerContent{
    padding: 50px 0;
    align-items: flex-start;
}

.footerContent .footerContentLeft{
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.footerContent .footerContentRight{
    flex: 1;
    flex-direction: column;
    gap: 15px;
}

.footerContentRight .contactDetailWrapper{
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.contactDetailWrapper div:last-child{
    margin-top: 20px;
}

.contactWrapper{
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.inputWrapper{
    flex-direction: column;
    gap: 10px;
}

.buttonWrapper{
    justify-content: flex-end;
}

.socialsWrapper{
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
}

.social{
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: rgba(128, 128, 128, 0.199);
    cursor: pointer;
    color: white;
    transition: 0.5s ease-in-out;
}

.social:hover{
    transform: scaleX(-1);
}

.portfolioSection{
    min-height: 100vh;
    width: 100vw;
    align-items: flex-start;
}

.portfolioSection .portfolioWrapper{
    width: 80%;
    height: 100%;
    align-items: flex-start;
    padding: 100px 0;
    gap: 20px;
}

.portfolioLeft{
    flex: 0.6;
    flex-direction: column;
}

.portfolioLeft .firstParaAndImage{
    margin-top: 30px;
    flex-direction: column;
    gap: 20px;
}

.content{
    text-align: justify;
    font-weight: 600;
}

.portfolioLeft .projectTitleWrapper{
    gap: 10px;
}

.image1{
    height: 400px;
    width: 100%;
    background-color: rgb(245, 245, 245);
}

.techUsed{
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.techImage{
    height: 80px;
    width: 80px;
    background-size: cover !important;
}

.secondContent{
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.projectTitleWrapper .logo{
    height: 70px;
    width: 70px;
}

.projectTitleWrapper .titleWrapper{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
}

.projectTitleWrapper .title{
    font-size: 35px;
    font-weight: 600;
}

.projectTitleWrapper .date{
    font-size: 18px;
}

.portfolioRight{
    padding: 0px 10px;
    flex: 0.4;
}

.portfolioRight .projectMenuWrapper{
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
}

.screenshotsContainer{
}

.screeShotsDiv{
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;

}

.screenshot{
    height: 500px;
    width: inherit;
    background-color: rgb(245, 245, 245);
}

.project{
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.textSel.selected{
    color: var(--secondary-color);
}

.iconSel.selected{
    height: 100px;
    width: 100px;
}

.iconSel:hover{
    transform: scale(1.05, 1.05);
}

.screenshotWrapper{
    gap: 10px;
    flex-wrap: wrap;
}

.screenshotWrapper::-webkit-scrollbar{
    visibility: hidden;
}

.screeShotsDiv .titleWrapper{
    justify-content: flex-start;
}
.screeShotsDiv .title{
    font-size: 30px;
    font-weight: 600;
}

.commentSection{
    flex-direction: column;
    margin-top: 50px;
}

.commentSection .commentInputWrapper{
    position: relative;
    background-color: rgb(244, 244, 244);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
    gap: 10px;
}

.commentSection .title{
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    top: -40px;
    background-color: rgb(244, 244, 244);
    padding: 10px;
    left: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.comments{
    flex-direction: column;
}

.comment{
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: rgb(244, 244, 244);
    margin-top: 10px;
    border-radius: 5px;
}

.comment .timePosted{
    font-size: 15px;
    font-weight: 500;
}

.comment .content{
    font-weight: 600;
    font-size: 18px;
    text-align: left;
}

.iconSel{
    display: none;
    height: 60px;
    width: 60px;
}

.pD{
    font-weight: 600;
}

@keyframes showCircle {
    50% {
      opacity: 0.5;
      transform: scale(1.2);
    }

    100%{
        opacity: 1;
        transform: scale(1);
    }
}

.introSection .name{
    display: none;
}
/* media queries */

@media screen and (max-width: 1075px) {
    .introSection .description{
        width: 100%;
        text-align: center;
        align-items: center;
        margin: 0;
    }

    .description .designation, .introduction{
        text-align: center !important;
    }

    .introSection .name{
        display: block;
    }

    .navBar .navLeft{
        display: none;
    }

    .navBar .navRight .sec{
        flex: 1;
    }

    .portfolioSection .portfolioWrapper{
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .portfolioWrapper{
        flex-direction: column-reverse;
    }

    .portfolioWrapper .portfolioRight{
        margin-bottom: 20px;
        width: 100%;
        border-bottom: 2px solid rgb(222, 222, 222);
        padding: 20px 0;
    }

    .portfolioWrapper .projectMenuWrapper{
        flex-direction: row;
        align-items: center;
    }

    .textSel{
        display: none;
    }

    .iconSel{
        display: block;
    }

    .navBar{
        width: 100% !important;
    }
    
}

@media screen and (max-width: 970px) {
    .experience .experienceTop{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .experience .experienceTopLeft{
        width: 100%;
        align-items: center;
    }

    .experience span{
        text-align: center !important;
    }

    .expertiseSection .expertiseCard{
        flex-direction: column-reverse;
        width: 98%;
    }

    .expertiseCard span{
        width: 100%;
    }

    .expertiseCardLeft .caret{
        display: none;
    }

    .expertiseCardRight{
        width: 100%;
    }

    .navBar .sec{
        padding: 0 5px !important;
    }

    .aboutInnerCard{
        padding: 10px;
        width: 98%
    }
}

@media screen and (max-width: 700px){
    .experiences{
        width: 98%;
    }

    .experienceSection{
        padding: 10px;
    }

    .sectionTitle{
        width: 100%;
    }

    .footerContent{
        flex-direction: column;
        gap: 20px;
    }

    .footerContent div{
        width: 100%;
    }

    .contactWrapper{
        align-items: center;
    }

    .socialsWrapper{
        justify-content: center;
        gap: 20px;
    }

    .socialsWrapper a{
        width: unset;
    }

    .sec{
        display: none;
    }

    .navRight{
        justify-content: center !important;
        gap: 10px;
    }

    .navLogo{
        display: flex !important;
    }

    .portfolioLeft .logo{
        display: none;
    }

    .portfolioLeft .titleWrapper{
        align-items: center;
    }

    .portfolioLeft .techImage{
        height: 50px;
        width: 50px;
    }
}

