@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@400..700&family=Roboto+Slab:wght@100..900&family=Shippori+Mincho&display=swap');
.App {
  text-align: center;
  align-items: flex-start;
}

.flexCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.width100{
  width: 100%;
}

.height100{
  height: 100%;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-style: normal;
}

:root{
  --primary-color: rgb(90, 255, 128);
  --secondary-color: rgb(1, 255, 86);
  --text-color: rgb(0,0,0)
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
