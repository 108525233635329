.navBar{
    position: absolute;
    z-index: 999;
    top: 0;
    height: 80px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.537);
    font-size: 18px;
    transition: 0.3s ease-in;
}

.navBar.floating{
    position: fixed;
    background-color: var(--secondary-color);
    width: calc(100% - 20px);
}

.name{
    font-weight: 800;
    font-size: 40px;
    font-family: "Dancing Script", cursive;
    font-weight: 700;
}

.navBar .navRight{
    flex: 1;
    height: 100%;
    justify-content: flex-start;
}

.navBar .navLeft{
    width: 30%;
    height: 100%;
}

.navRight .sec{
    height: 100%;
    border-right: 1px solid rgba(128, 128, 128, 0.537);
    padding: 0px 40px;
    cursor: pointer;
}

.navRight .sec:last-child{
    border-right: 0;
}

.navRight .sec:first-child{
    border-left: 1px solid rgba(128, 128, 128, 0.537);
}

.sec:hover{
    background-color: rgba(0, 128, 0, 0.237);
}

a{
    text-decoration: none;
    color: black;
    width: 100%;
    font-weight: 600;
}

.navLogo{
    display: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgba(128, 128, 128, 0.234);
    color: rgba(0, 0, 0, 0.585);
    cursor: pointer;
}

.navLogo.selected{
    border: 2px solid black;
    color: black;    
}