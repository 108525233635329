.button{
    padding: 10px 50px;
    height: 60px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.theme1.button:hover{
    background-color: black;
    color: white;
}

.theme2.button:hover{
    background-color: transparent;
    color: black;
    border: 1px solid black;
}

.theme3.button:hover{
    background-color: transparent;
    color: black;
    border: 1px solid black;
    font-weight: 600;
}

.button .buttonText{
    font-weight: 600;
    font-size: 17px;
}

.theme1{
    border: 1px solid black;
}

.theme2{
    background-color: var(--secondary-color);
}

.theme3{
    background-color: black;
    color: white;
}

.theme3 .buttonText{
    font-weight: 500;
}

.theme3.button:hover{
    color: black;
    font-weight: 600;
}
